import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';

const NavStyles = styled.nav`
  /* margin-bottom: 3rem; */
  .logo {
    transform: translateY(-25%);
  }
  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    margin-top: -6rem;
    display: grid;
    grid-template-columns: 1fr 1fr auto 1fr 1fr;
    gap: 2rem;
    align-items: center;
    margin-top: -6rem;
  }

  li {
    --rotate: -2deg;
    transform: rotate(var(--rotate));
    order: 1;
    &:nth-child(1) {
      --rotate: 1deg;
    }
    &:nth-child(2) {
      --rotate: -2.5deg;
    }
    &:nth-child(4) {
      --rotate: 2.5deg;
    }
    &:nth-child(5) {
      --rotate: -1deg;
    }
    &:hover {
      --rotate: 3deg;
    }
    a {
      font-size: 3rem;
      text-decoration: none;
      display: block;
      &:hover {
        color: var(--red);
      }
      /* &[aria-current] {
        color: var(--red);
      } */
    }
    @media (max-width: 600px) {
      --columns: 4;
      margin-bottom: 2rem;
      border-bottom: 2px solid var(--grey);
      padding-bottom: 2rem;
      ul {
        grid-template-rows: auto auto;
        grid-template-columns: repeat(var(--columns), 1fr);
        justify-items: center;
      }
      .logo-item {
        order: 0;
        grid-column: 1 / -1;
      }
      .logo {
        transform: none;
      }
    }
    @media (max-width: 500px) {
      --columns: 2;
    }
  }
`;

function Nav() {
  return (
    <NavStyles>
      <ul>
        <li>
          <Link to="/">Hot now</Link>
        </li>
        {/* <li>
          <Link to="/beers">Beers</Link>
        </li> */}
        <li>
          <Link to="/pizzas">Pizza Menu</Link>
        </li>
        <li>
          <Link className="logo-item" to="/">
            <Logo />
          </Link>
        </li>
        <li>
          <Link to="/slicemasters">Slicemasters</Link>
        </li>
        <li>
          <Link to="/order">Order Ahead!</Link>
        </li>
      </ul>
    </NavStyles>
  );
}

export default Nav;
